html,
body {
  overscroll-behavior-x: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.unselectable {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.border-shortbread-magenta {
  border-color: #ff00ff;
  outline-color: #ff00ff;
}

.bg-shortbread-magenta {
  border-color: #ff00ff;
}

.border-shortbread-yellow {
  border-color: #f5c74a;
}

.bg-shortbread-yellow {
  background-color: #ffd700;
}

.bg-shortbread-pink {
  background-color: #ffb6c1;
}

.bg-shortbread-mint {
  background-color: #98ff98;
}

.bg-shortbread-purple {
  background-color: #7c68f5;
}

.border-shortbread-purple {
  border-color: #7c68f5;
}

.gradient-background {
  background: linear-gradient(300deg, #f2d338, #f2b807, #f28907);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

.gradient-background-blue {
  background: linear-gradient(300deg, #422ff1, #033e8c, #5f49f2);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

@layer utilities {
  .gradient-background-blue {
    background: linear-gradient(300deg, #0b4cff, #0964ec, #2f12ee);
    background-size: 180% 180%;
    animation: gradient-animation 18s ease infinite;
  }
  .gradient-background {
    background: linear-gradient(300deg, #f2d338, #f2b807, #f28907);
    background-size: 180% 180%;
    animation: gradient-animation 18s ease infinite;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.glassmorphic-container {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 36px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.2px);
  -webkit-backdrop-filter: blur(13.2px);
}
.glassmorphic-container-border {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 14px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.2px);
  -webkit-backdrop-filter: blur(13.2px);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.comic-item:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  background-color: rgba(255, 255, 255, 0.1);
}

.rotate-animation {
  animation: rotate 10s linear infinite;
}

.highlight-effect {
  transition: background-color 0.3s ease;
  padding: 5px;
  border-radius: 5px;
}

.highlight-effect:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

img {
  max-width: 100%;
  height: auto;
}

.svg-button:hover {
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.3s ease;
}

.ProseMirror:focus {
  outline: none;
}

.div-hover:hover .fill-current path {
  fill: #ffffff;
}

.fill-current path {
  transition: fill 400ms;
}

.menu {
  opacity: 0;
  visibility: hidden;
  transition: opacity 400ms ease-in-out, visibility 400ms ease-in-out;
}

.menu.open {
  opacity: 1;
  visibility: visible;
}

.menu.closed {
  opacity: 0;
  visibility: hidden;
}

.arrow {
  transition: transform 400ms ease-in-out;
  transform: rotate(180deg);
}

.arrow.open {
  transform: rotate(0deg);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer base {
  :root {
    --background: 0, 0%, 9%; /* gray-900 */
    --foreground: 0, 0%, 98%; /* gray-50 */
    --card: 0, 0%, 9%; /* gray-900 */
    --card-foreground: 0, 0%, 98%; /* gray-50 */
    --popover: 0, 0%, 9%; /* gray-900 */
    --popover-foreground: 0, 0%, 98%; /* gray-50 */
    --primary: 0, 0%, 9%; /* gray-900 */
    --primary-foreground: 0, 0%, 98%;
    --secondary: 0, 0%, 98%; /* gray-800 */
    --secondary-foreground: 0, 0%, 0%; /* gray-50 */
    --muted: 240, 4%, 16%; /* gray-800 */
    --muted-foreground: 240, 5%, 65%; /* gray-400 */
    --accent: 240, 4%, 16%; /* gray-800 */
    --accent-foreground: 0, 0%, 98%; /* gray-50 */
    --destructive: 0, 63%, 31%; /* red-800 */
    --destructive-foreground: 0, 0%, 98%; /* gray-50 */
    --border: 240, 4%, 16%; /* gray-800 */
    --input: 240, 4%, 16%; /* gray-800 */
    --ring: 225, 16%, 54%; /* yellow-500 */
    --chart-1: 217, 91%, 60%; /* blue-500 */
    --chart-2: 160, 84%, 39%; /* green-500 */
    --chart-3: 38, 92%, 50%; /* yellow-500 */
    --chart-4: 271, 91%, 65%; /* purple-500 */
    --chart-5: 330, 81%, 60%; /* pink-500 */
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
